import URLManager from './URLManager';
import history from './history';
import CacheEnum from './Cache.enum';
import debounce from './debounce';
import isEqual from './isEqual';

export interface IBorderStyleArgs {
  fat?: number | string;
  borderRadius?: number | string;
  borderColor?: string;
  hideAll?: boolean;
  hideBottom?: boolean;
  backgroundColor?: string;
  leftTopAngle?: boolean;
  leftBottomAngle?: boolean;
  rightTopAngle?: boolean;
  rightBottomAngle?: boolean;
  hideRight?: boolean;
  hideTop?: boolean;
  hideLeft?: boolean;
  glow?: string
}

export default class UTILS {
  static getInPx = (number: number) => `${number}px`;

  static getImportant = (v: string) => `${v} !important`;

  static isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  static history = history;

  static debounce = debounce;

  static URLManager = URLManager;

  static toOffer = () => {
    const data = document.getElementById('Offer');
    data.scrollIntoView({
      block: 'start',
      behavior: 'smooth'
    });
  };

  static RequestCacheEnum = CacheEnum;

  static getBorderStyle = ({
    fat,
    borderRadius,
    borderColor = 'transparent',
    backgroundColor = 'transparent',
    leftBottomAngle = false,
    rightBottomAngle = false,
    hideRight = false,
    hideTop = false,
    hideLeft = false,
    rightTopAngle = false,
    leftTopAngle = false,
    glow = '',
    hideAll = false,
    hideBottom = false,
  }: IBorderStyleArgs) => {
    let leftBottomRadius = this.getInPx(0);
    let rightBottomRadius = this.getInPx(0);
    let leftTopRadius = this.getInPx(0);
    let rightTopRadius = this.getInPx(0);
    if (typeof borderRadius !== 'string') {
      borderRadius = this.getInPx(borderRadius);
    }
    if (typeof fat !== 'string') {
      fat = this.getInPx(fat);
    }
    if (leftTopAngle) {
      leftTopRadius = borderRadius;
    }
    if (rightTopAngle) {
      rightTopRadius = borderRadius;
    }
    if (rightBottomAngle) {
      rightBottomRadius = borderRadius;
    }
    if (leftBottomAngle) {
      leftBottomRadius = borderRadius;
    }

    return {
      borderTop: `${(hideAll || hideTop) ? 0 : fat} solid ${borderColor}`,
      borderRight: `${hideAll || hideRight ? 0 : fat} solid ${borderColor}`,
      borderBottom: `${hideAll || hideBottom ? 0 : fat} solid ${borderColor}`,
      borderLeft: `${hideAll || hideLeft ? 0 : fat} solid ${borderColor}`,
      borderColor,
      backgroundOrigin: 'border-box !important',
      backgroundRepeat: 'no-repeat !important',
      background: `linear-gradient(45deg, ${borderColor} 0 calc(var(--bottom-left, ${leftBottomRadius}) + 0px), transparent 0) bottom left /50% 50%,
      linear-gradient(-45deg, ${borderColor} 0 calc(var(--bottom-right, ${rightBottomRadius}) + 0px), transparent 0) bottom right/50% 50%,
      linear-gradient(135deg, ${borderColor} 0 calc(var(--top-left, ${leftTopRadius}) + 0px), transparent 0) top left    /50% 50%,
      linear-gradient(-135deg, ${borderColor} 0 calc(var(--top-right, ${rightTopRadius}) + 0px), transparent 0) top right   /50% 50%,
      var(--img, ${backgroundColor})
      ${glow}
      `,
      clipPath: `polygon(${leftTopRadius} 0%, calc(100% - ${rightTopRadius}) 0%, 100% ${borderRadius}, 100% calc(100% - ${rightBottomRadius}), calc(100% - ${borderRadius}) 100%, ${borderRadius} 100%, 0% calc(100% - ${leftBottomRadius}), 0% ${borderRadius})`,
    };
  };

  static numberWithSpaces(x) {
    if (String(x).includes('.')) {
      const parts = x.toString().split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      return parts.join('.');
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  static decodeHtmlCharCodes = str => {
      const decodedString = document.createElement('textarea');
      decodedString.innerHTML = str;
      return decodedString.value;
    };

  static isEqual = isEqual;

  static getLocale = () => {
      const localesFromQuery = UTILS.URLManager.getQueryParams('locale');
      let windowLocale = window.localStorage.locale;
      if (windowLocale === 'undefined' || windowLocale === 'null') {
        windowLocale = 'ru';
      }
      return localesFromQuery || windowLocale || 'ru';
    };
}
