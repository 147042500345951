import { ThemeOptions } from '@mui/material';
import Colors from '$colors';

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    dark: true;
  }
}

const paper: ThemeOptions['components'] = {
  MuiPaper: {
    variants: [
      {
        props: { variant: 'dark' },
        style: {
          width: '100%',
          display: 'flex',
          background: Colors.paperBackgroundColor,
          flexDirection: 'column',
          padding: 0,
          borderRadius: 20,
        },
      },
    ],
  },
};
export default paper;
