import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';
import Colors from '$colors';
import SvgIcons from "$common/SvgIcons";



const button: ThemeOptions['components'] = {
  MuiButton: {
    variants: [
      {
        props: {
          variant: "action",
        },
        style: {
          ...UTILS.getBorderStyle({
            backgroundColor: Colors.primary,
            borderColor: Colors.primary,
            rightBottomAngle: true,
            fat: "2px",
            borderRadius: "24px"
          }),
          color: "white",
          "&:hover":{
            backgroundColor: Colors.primary,
            color: "white",
            transition: 'none',
            transform: 'none',
          }
        }
      }
    ],
    styleOverrides: {
      text: {
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiButton-endIcon': {
          margin: 0,
          padding: 0,
        },
        '& .MuiButton-startIcon': {
          margin: 0,
          padding: 0,
          paddingRight: UTILS.getInPx(24),
        },
      },
      contained: {
        maxHeight: "53px"
      },
      root: {
        backgroundColor: null,
        '&:hover': {
          backgroundColor: null,
          transition: 'all 0.1s linear',
          transform: 'scale(1.06)',
        },
      },
    },
    defaultProps: {
      disableTouchRipple: true,
    },
  },
};

export default button;
