import { ThemeOptions } from "@mui/material";
import { isMobile } from "react-device-detect";

const getInPx = (number: number) => `${number}px`;

console.log("isMobile: ", isMobile);

const typography: ThemeOptions["typography"] = {
	fontFamily: ["Gotham"],
	allVariants: {
		fontStyle: "normal",
		fontWeight: 500,
		textTransform: "none",
	},
	// Прям название сайта
	h1: {
		fontWeight: 700,
		fontFamily: ["Druk"],
		fontStretch: "200%",
		fontSize: getInPx(isMobile ? 18 : 40),
		lineHeight: getInPx(isMobile ? 24 : 52),
	},
	h2: {
		fontSize: getInPx(isMobile ? 36 : 32),
		lineHeight: getInPx(isMobile ? 36 : 40),
	},
	h3: {
		fontFamily: ["Druk"],
		fontSize: getInPx(isMobile ? 20 : 30),
		fontStretch: "200%",
		lineHeight: getInPx(isMobile ? 20 : 40),
	},
	h4: {
		fontSize: getInPx(24),
		lineHeight: getInPx(30),
	},
	// Текст в карточках
	body1: {
		fontWeight: 400,
		fontSize: getInPx(16),
		lineHeight: getInPx(22),
	},
	body2: {
		fontWeight: 400,
		fontSize: getInPx(14),
		lineHeight: getInPx(20),
	},
	subtitle1: {
		fontSize: getInPx(18),
		lineHeight: getInPx(25),
	},
	subtitle2: {
		fontSize: getInPx(16),
		lineHeight: getInPx(22),
	},
	button: {
		fontSize: getInPx(12),
		lineHeight: getInPx(14),
		textTransform: "uppercase",
	},
	// лейблы к инпутам
	caption: {
		fontSize: getInPx(13),
		lineHeight: getInPx(18),
	},
	// Всякие Badge ну тип уведомления
	h5: {
		fontWeight: 400,
		fontSize: getInPx(13),
		lineHeight: getInPx(15),
	},

	// LATO
	h6: {
		fontFamily: ["Lato"],
		fontSize: getInPx(isMobile ? 22 : 28),
		lineHeight: getInPx(isMobile ? 22 : 28),
	},
};

export default typography;
