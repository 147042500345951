import { createTheme } from '@mui/material';
import Colors from '$colors';
import typography from '$styles/typography';
import palette from './palette';
import UTILS from '../utils';
import muiThemeLab from '$styles/components/muiThemeLab';
import paper from '$styles/components/Paper';
import button from '$styles/components/Button';
import iconButton from '$styles/components/IconButton';
import textField from '$styles/components/TextField';
import chip from '$styles/components/Chip';
import inputAdornment from '$styles/components/InputAdornment';
import menu from '$styles/components/Menu';
import dialog from '$styles/components/Dialog';
import accordion from '$styles/components/Accordion';
import collapse from '$styles/components/Collapse';
import Switch from '$styles/components/Switch';

const getColors = (props) => {
  return CSS.supports('color', props?.color) ? props?.color : Colors[props?.color] || {};
};

const theme = createTheme({
  palette,
  typography,
  components: {
    ...muiThemeLab,
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          ".MuiDataGrid-columnHeaders": {
            border: "none",
            borderRadius: "16px",
            backgroundColor: Colors.paperHeaderBackgroundColor,
            color: "white"
          },
          ".MuiDataGrid-cell": {
            color: Colors.gray4,
            borderColor: Colors.gray6,
          },
          ".no-separator": {
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            }
          },
          ".MuiDataGrid-footerContainer": {
            border: "none",
            "& > div": {
              display: "contents",
            }
          },
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          display: 'flex !important',
          width: "100%",
          color: Colors.gray6,
          justifyContent: "start"
        },
        toolbar: {
          width: "100%",
          justifyContent: "space-between",
          ".MuiTablePagination-spacer": {
            display: "contents"
          }
        },
        actions: {
          "& button:first-child": {
            borderRight: "1px solid " + Colors.gray6,
            borderRadius: "16px 0px 0px 16px",
          },
          
          color: Colors.gray6,
          borderRadius: "16px",
          "svg": {
            color: "white"
          },
          border: "1px solid " + Colors.gray6,
        }
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState }) => {
          if (ownerState?.color?.includes('gradient')) {
            const color = getColors(ownerState);
            return ({
              ...({
                backgroundImage: UTILS.isEmpty(color) ? ownerState.color : color,
                backgroundClip: 'text',
                color: 'transparent',
                padding: UTILS.getInPx(8),
                whiteSpace: 'pre-line',
              }),
            });
          }

          return ({
            ...({
              color: getColors(ownerState),
              whiteSpace: 'pre-line',

            }),
          });
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return ({
            ...({
              height: UTILS.getInPx(2),
              borderColor: getColors(ownerState),
              opacity: 1,
            }),
          });
        },
      },
    },

    MuiAccordionSummary: accordion?.MuiAccordionSummary,
    MuiDialog: dialog.MuiDialog,
    MuiAccordion: accordion?.MuiAccordion,
    MuiCollapse: collapse?.MuiCollapse,
    MuiSwitch: Switch?.MuiSwitch,
    MuiMenu: menu?.MuiMenu,
    MuiChip: chip?.MuiChip,
    MuiInputAdornment: inputAdornment?.MuiInputAdornment,
    MuiTextField: textField?.MuiTextField,
    MuiIconButton: iconButton?.MuiIconButton,
    MuiButton: button?.MuiButton,
    MuiPaper: paper?.MuiPaper,
  },
});

export default theme;
