import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import SvgIcons from '$common/SvgIcons';
import Colors from '$colors';
import logo from '$images/logo.png';
import { RouterEnum } from '$types';
import "./index.scss"
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';

function Footer(props) {
  const { t } = useTranslation();
  return (
    <footer className="w-100 justify-content-center d-flex">
      <div className="container d-flex justify-content-center align-items-center">
        <div className="d-flex flex-row gap-4 flex-wrap align-baseline p-4 justify-content-between w-100">
          <div className="d-flex flex-column justify-content-between gap-2" style={{ maxWidth: '500px' }}>
            <div className="d-flex flex-row align-items-center gap-4">
              <img className="align-self-start" src={logo} width={110} />
              <a
                id="youtube_channel"
                href="https://t.me/web3academy_pro"
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcons
                  className="cursor-pointer"
                  name="youtube"
                  onClick={() => {
                    window.open('https://www.youtube.com/channel/UCmT4sCvAUUn77543vjh2Izg', '_blank');
                  }}
                />
              </a>
              <a
                id="discord_server"
                href="https://t.me/web3academy_pro"
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcons
                  className="cursor-pointer"
                  name="discord"
                  color={Colors.white}
                  onClick={() => {
                    window.open('https://discord.gg/bWftgunvMM', '_blank');
                  }}
                />
              </a>
              <a
                id="tg_channel"
                href="https://t.me/web3academy_pro"
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcons
                  className="cursor-pointer"
                  name="telegram"
                  color={Colors.white}
                />
              </a>
            </div>
            <Typography variant="body2" color="white">
              {t('footer.caption')}
            </Typography>
          </div>
          <div className={`d-flex  ${isMobile ? 'flex-column' : 'flex-row'} justify-content-between gap-5`}>
            <div className="d-flex flex-column gap-2 justify-content-between">
              <Typography variant="h5" color="white">{t("footer.IP_NAME")}</Typography>
              <Typography variant="h5" color="white">{t("footer.IP_INN")}</Typography>
              <Typography variant="h5" color="white">{t("footer.IP_OGRN")}</Typography>
            </div>
            <div className="d-flex flex-column gap-2 justify-content-between">
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                <Link
                  underline="hover"
                  color="white"
                  href="https://docs.google.com/document/d/1mwGXDgaUg5GKaj8qMbNqI8RANhSwUxdHrZvfsQ2fPvM/edit"
                >
                  <Typography variant="caption" color="white"> {t("footer.userPolicy")}</Typography>
                </Link>
              </Breadcrumbs>
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                <Link
                  underline="hover"
                  color="white"
                  href={RouterEnum.TERMS_OF_SERVICE}
                >
                  <Typography variant="caption" color="white">{t("footer.publicOffer")}</Typography>
                </Link>
              </Breadcrumbs>
              <a className={"p-0"} href="mailto:support@web3academy.pro">
                <Typography
                  variant="h5"
                  color="white"
                >
                  support@web3academy.pro
                </Typography>
              </a>
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
}

export default Footer;
