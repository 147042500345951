import React from "react";
import {
	Box,
	Button,
	CardHeader,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Typography,
} from "@mui/material";
import BaseNotification from "./BaseNotification";
import BaseNotificationWindow from "./BaseNotificationWindow";
import CommonCard from "$components/cards/CommonCard";
import logo from "$images/logo.png";
import i18next from "i18next";
import "./AlertWindow.scss";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import NotificationManager from "helpers/NotificationManager";

interface IConfirmConfig {
	title?: string;
	buttonCancelText?: string;
	message: string;
	onClose?: () => void;
}

class AlertWindow extends BaseNotification implements BaseNotificationWindow {
	private title: string;

	private message: string;

	private buttonCancelText?: string;

	private onClose: (() => void) | undefined;

	open(config: IConfirmConfig) {
		this.title = config.title;
		this.message = config.message;
		this.buttonCancelText = config.buttonCancelText;
		this.onClose = config.onClose;

		this.sendNotify();
	}

	getMessage(): React.ReactNode {
		return <p>{this.message}</p>;
	}

	getNotificationWindow(): React.ReactNode {
		return (
			<Dialog
				open
				onClose={() => {
					this.close();
				}}
				minWidth={376}
				maxWidth="lg"
			>
				<Paper variant="dark">
					<div
						className={
							"alert-window-container w-100 h-100 d-flex flex-column justify-content-center align-items-center gap-4"
						}
					>
						<IconButton
							aria-label="close"
							onClick={() => this.close()}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
							}}
						>
							<CloseIcon color="white50" />
						</IconButton>
						<DialogContent className="dialogContent" sx={{ padding: 0 }}>
							{this.title && (
								<Box sx={{ mb: "10px" }}>
									<Typography
										textAlign={"center"}
										color="white"
										textTransform="uppercase"
										variant="h3"
									>
										{this.title}
									</Typography>
								</Box>
							)}
							<Typography variant="body2" textAlign={"center"} color="white50">
								{this.message}
							</Typography>
						</DialogContent>
						<DialogActions className="justify-content-center alert-button">
							<Button
								variant={"pro_secondary"}
								onClick={() => {
									this.close();
									this.onClose && this.onClose();
								}}
							>
								{this.buttonCancelText || i18next.t("common.close")}
							</Button>
						</DialogActions>
					</div>
				</Paper>
			</Dialog>
		);
	}

	getIcon(): React.ReactNode {
		return null;
	}
}

export default AlertWindow;
