import { ThemeOptions } from '@mui/material';
import UTILS from '../../utils';
import Colors from '$colors';

const chip: ThemeOptions['components'] = {
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: 8
      },
      colorError: {
        backgroundColor: UTILS.getImportant(Colors.black),
        color: 'white !important',
        border: UTILS.getImportant('none'),
      },
      colorSecondary: {
        backgroundColor: UTILS.getImportant(Colors.gray6),
        color: UTILS.getImportant(Colors.white),
        border: UTILS.getImportant('none'),

      },
      colorSuccess: {
        background: UTILS.getImportant(Colors.green3),
        color: UTILS.getImportant(Colors.green10),
        border: UTILS.getImportant('none'),
      },
      colorInfo: {
        background: "rgba(255, 255, 255, 0.1)",
        color: "white",
        border: UTILS.getImportant('none'),
      },
      outlined: {
        color: Colors.blue4,
        border: `1px solid ${Colors.gray7}`,
        background: Colors.gray9,
        userSelect: 'none',
      },
    },
  },
};
export default chip
