import React, { useEffect, useState } from "react";
import "./index.scss";

import { Button, IconButton, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isMobile } from "react-device-detect";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PopupState, { bindTrigger, bindMenu, usePopupState } from "material-ui-popup-state";
import { useTranslation } from "react-i18next";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoginIcon from "@mui/icons-material/Login";
import MobXRouterDecorator from "$components/HOC/MobXRouterDecorator";
import { MOBXDefaultProps, OfferNames, RouterEnum } from "$types";
import LinkLine from "$components/LinkLine";
import SvgIcons from "$common/SvgIcons";
import logo from "$images/logo.png";
import { useRootStore } from "$hooks/useRootStore";
import Colors from "$colors";
import { useRootService } from "$hooks/useRootService";
import { toJS } from "mobx";

function Header(props: MOBXDefaultProps) {
  const store = useRootStore();
  const services = useRootService();
  const { user } = store.UserStore;
  const isUserAuth = !!user?.isActivated;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  console.log(toJS(user));
  const isVip =
    "rates_name" in user &&
    user.rates_name !== null &&
    (user.rates_name.includes(OfferNames.VIP) ||
      user.rates_name.includes(OfferNames.PRO_RATES) ||
      user.rates_name.includes(OfferNames.PRO_RATES_MINICOURSE));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const headerArray = [
    {
      text: t("header.lessons"),
      icon: <SvgIcons name="lessons" color={Colors.gray2} />,
      cb: () => {
        props.history.push(RouterEnum.CATEGORY);
      },
    },
    {
      text: t("header.tools"),
      icon: <SvgIcons name="settings" width={22} height={22} />,
      cb: () => {
        props.history.push(RouterEnum.TOOLS);
      },
    },
    {
      text: "Материалы",
      icon: <SvgIcons name="books" width={22} height={22} />,
      cb: () => {
        props.history.push(RouterEnum.OTHER);
      },
    },
    {
      text: "Новости",
      icon: <SvgIcons name="news" width={22} height={22} />,
      cb: () => {
        props.history.push(RouterEnum.NEWS);
      },
    },
  ];

  const menuArray = [
    {
      condition: isUserAuth,
      text: "Мой Кабинет",
      icon: <SvgIcons name="profile" width={22} height={22} />,
      cb: (e) => {
        props.history.push(RouterEnum.PROFILE);
      },
    },
    {
      condition: isUserAuth,
      text: t("header.partner_profile"),

      icon: <SvgIcons name="partners_profile" width={22} height={22} />,
      cb: (e) => {
        props.history.push(RouterEnum.REFERRER);
      },
    },

    {
      condition: isVip && isUserAuth,
      text: "Academy Pro",

      icon: <SvgIcons name="crown-star-bold" width={22} height={22} />,
      cb: (e) => {
        props.history.push(RouterEnum.ACADEMYPRO);
      },
    },
    {
      condition: isUserAuth,
      text: t("header.logout"),
      icon: <ExitToAppIcon style={{ color: Colors.blue1 }} />,
      cb: (e) => {
        services.AuthService.logout();
      },
    },
  ];

  const getSignupButton = () => {
    if (isMobile) return null;
    if (isUserAuth) {
      return (
        <Button
          color="primary"
          onClick={(e) => {
            handleClick(e);
          }}
          endIcon={<ArrowDropDownIcon style={{ color: Colors.gray2 }} />}
        >
          <Typography variant="h5" color={Colors.white}>
            Мой Кабинет
          </Typography>
        </Button>
      );
    }

    return (
      <Button
        color="primary"
        onClick={(e) => {
          props.history.push(RouterEnum.SIGNIN);
        }}
      >
        <Typography variant="caption" color={Colors.white}>
          Вход
        </Typography>
      </Button>
    );
  };

  const getMenuProps = (popupState) => {
    const sx = {
      ".MuiMenu-paper": {
        backgroundColor: Colors.paperBackgroundColor,
      },
    };
    if (isMobile) {
      return {
        ...bindMenu(popupState),
        style: {
          width: "100%",
        },
        sx: {
          ".MuiMenu-paper": {
            width: "100%",
            left: 0 + " !important",
            maxWidth: "100%",
            height: "100%",
            backgroundColor: Colors.paperBackgroundColor,
          },
        },
      };
    }
    return {
      anchorEl,
      open,
      onClose: handleClose,
      sx,
    };
  };

  const mobileLoginButton = {
    condition: !isUserAuth,
    text: t("header.signIn"),
    icon: <LoginIcon style={{ color: "#6272FF" }} />,
    cb: (e) => {
      props.history.push(RouterEnum.SIGNIN);
    },
  };

  const renderArray = isMobile
    ? [
        ...(!isUserAuth ? [mobileLoginButton] : []),
        ...headerArray,
        ...menuArray.filter((element) => Boolean(element.condition)),
      ]
    : [...menuArray.filter((element) => Boolean(element.condition))];

  return (
    <header className="d-flex justify-content-center w-100">
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <div className="container d-flex align-items-center justify-content-between w-100 py-4 px-4">
              <div
                className="cursor-pointer d-flex flex-row gap-2 align-items-center user-select-none"
                onClick={() => props.history.push(RouterEnum.HOME)}
              >
                <img src={logo} width={132} />
              </div>
              {!isMobile && (
                <>
                  <LinkLine key={1} headerArray={headerArray} />
                  {isVip && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => {
                        props.history.push(RouterEnum.ACADEMYPRO + "#Offer");
                      }}
                    >
                      <Typography variant="caption" color={Colors.white}>
                        Academy PRO
                      </Typography>
                    </Button>
                  )}
                </>
              )}
              <div className="d-flex flex-row align-items-center gap-2">
                {getSignupButton()}
                {isMobile && (
                  <IconButton {...bindTrigger(popupState)}>
                    <MenuIcon color="white" />
                  </IconButton>
                )}
              </div>
            </div>
            <Menu {...getMenuProps(popupState)}>
              {isMobile && (
                <MenuItem onClick={popupState.close}>
                  <Typography color="white">Меню</Typography>
                </MenuItem>
              )}
              {renderArray.map((item) => {
                return (
                  <MenuItem onClick={popupState.close}>
                    <Button
                      className="ps-1"
                      key={item.text}
                      onClick={(e) => {
                        item.cb(e);
                        handleClose();
                      }}
                      style={{
                        maxWidth: 300,
                        boxShadow: "none",
                      }}
                      startIcon={item.icon}
                    >
                      <Typography variant="h5" color={Colors.white}>
                        {item.text}
                      </Typography>
                    </Button>
                  </MenuItem>
                );
              })}
            </Menu>
          </>
        )}
      </PopupState>
    </header>
  );
}

export default MobXRouterDecorator(Header);
