import React, {
  useCallback, useEffect
} from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Button, Menu, MenuItem, Typography,
} from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { useTranslation } from 'react-i18next';
import MobXRouterDecorator from '$components/HOC/MobXRouterDecorator';
import { MOBXDefaultProps, RolesTypes, RouterEnum } from '$types';
import Colors from '$colors';
import { useRootStore } from '$hooks/useRootStore';
import { useRootService } from '$hooks/useRootService';

interface ILinkLineProps extends MOBXDefaultProps{
  headerArray: any;
}

function LinkLine(props: ILinkLineProps) {
  const { headerArray } = props;

  const getComponents = (item, header = true) => {
    if ('condition' in item && !item.condition) return null;
    return (
      <Button
        className={(item.className || '') + (!header ? ' pointer-event-none' : '')}
        key={item.text}
        variant={'link'}
        onClick={(e) => {
          item.cb(e);
        }}
        style={{
          maxWidth: 300,
          boxShadow: 'none',
        }}
        startIcon={header && item.noIconInHeader ? null : item.icon}
        endIcon={item.endIcon || null}
      >
        <Typography
          variant="h5"
          color={!header ? Colors.black : item.textColor || Colors.white}
        >
          {item.text}
        </Typography>
      </Button>
    );
  };


  return (
    <div className="d-flex justify-content-center flex-row gap-2 align-items-center w-fit">
      {headerArray.map(el => getComponents(el))}
    </div>
  );
}

export default MobXRouterDecorator(LinkLine);
